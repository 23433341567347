
<template>
  <div class="tab-content2">
    <img src="../assets/banner2.png" class="banner">
    <div class="content">
      <div class="tabs">
        <template v-for="(item,index) in list">
          <div class="tabs-item" :key="index">
            <span :class="[0===index ? 'active': '']">{{item}}</span>
          </div>
        </template>
      </div>
      <div style="display:flex;margin-top:60px">
        <img src="../assets/tab2/1.png" style="width:600px;height:530px">
        <div style="flex-1;margin-left:57px;">
          <div style="font-weight: bold;font-size: 20px;color: #333333;line-height: 24px;margin:30px 0;">银行代收付通道</div>
          <div style="font-size: 16px;color: #666666;line-height: 30px;">
            <div>
              银行代收付通道是指金融机构提供的一种金融服务，它允许企业或个人通过银行系统实现资金的集中代收或代付处理。
              这一服务在现代社会经济活动中扮演着至关重要的角色，尤其对于需要频繁处理大量交易的企业而言，如电商平台、公共服务提供商（水电煤缴费）、保险机构及各种订阅服务等。
              下面将从其定义、运作流程、优势以及应用场景等方面进行详细阐述
            </div>
            <div style="margin-top:30px">定义</div>
            <div>
              银行代收付通道是一种授权机制，通过它，商户可以委托银行作为中介，自动从客户的银行账户中扣取应付金额或向客户账户支付款项。这一过程无需客户手动转账，提高了交易效率和便捷性。
            </div>
          </div>
        </div>
      </div>
      <div style="font-size: 16px;color: #666666;line-height: 30px;margin-top:143px">
        <div style="margin-bottom:20px"> 运作流程</div>
        <div> 
          01 签约授权：首先，企业和银行签订代收付协议，同时，客户需向企业提供授权，同意银行在其账户上执行代收或代付操作。<br/>
          02 信息上传：企业将需要代收或代付的交易信息（如收款人账号、金额等）通过银行提供的接口或系统上传。<br/>
          03 银行处理：银行系统验证交易信息无误后，自动执行扣款或付款操作。对于代收，银行从客户的账户扣除相应款项并转入企业账户；对于代付，则是将资金从企业账户转出至指定收款人账户。<br/>
          04 结果反馈：交易完成后，银行将处理结果（成功或失败）及时反馈给企业，企业再根据反馈更新自己的系统记录，完成整个交易闭环。
        </div>
        <div style="margin-top:60px;margin-bottom:20px"> 应用场景</div>
        <div> 
          01 电子商务：电商平台利用代收通道实现快速结算，提高购物体验。<br/>
          02 公共服务：水电气费、通讯费等定期费用的自动扣缴，简化缴费流程。<br/>
          03 保险行业：保费的自动扣缴和理赔款项的发放，提升服务效率。<br/>
          04 教育与会员制服务：学费、会员费的定期扣款，确保稳定现金流。
        </div>
      </div>
    </div>
  </div>                                                                                                                            
</template>

<script>
export default {
  name: 'TabContent2',
  data(){
    return {
      list: ["银行代收付通道","软件技术开发","数字化服务"]
    }
  }
}
</script>

<style scoped>
.tab-content2{
  padding-bottom: 120px;
}
.banner{
  height: 600px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 0 auto;
 }

.tabs{
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  height: 60px;
}

.tabs > .tabs-item{
  position: relative;
  line-height: 36px;
  margin: 0 15px;
  color: #333333;
}

.tabs > .tabs-item > .active::after{
  content: '';
  position: absolute;
  width: auto;
  height: 2px;
  background: #0B3591;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>

<template>
  <div class="tab-content3">
    <img src="../assets/banner3.png" class="banner">
    <div class="content">
      <div style="padding:30px 0;display:flex;" v-for="(item,index) in list" :key="index">
        <div style="display:flex;flex-direction:column;align-items:center">
          <span style="font-weight: 600;font-size: 40px;color: #222222;">{{item.day}}</span>
          <span style="font-size: 18px;color: #999999;">{{item.month}}</span>
        </div>
        <span style="width: 19px;height: 5px;background: #0B3591;margin-left:23px"></span>
        <img :src="item.image" style="width:220px;height:160px;margin:0 33px 0 39px">
        <div style="padding-top:19px;">
          <div style="font-weight: bold;font-size: 20px;color: #222222;line-height: 23px;">{{item.title}}</div>
          <div style="margin-top:25px;font-size: 14px;color: #666666;line-height: 32px;">{{item.desc}}</div>
        </div>
      </div>
      <div style="display:flex;justify-content:center;margin-top:30px;">
        <div style="font-size: 14px;color: #333333;width: 92px;height: 38px;text-align:center;line-height:38px; border: 1px solid #DCDCDC">
          《上一页
        </div>
       <template v-for="item in 3">
         <div 
         :style="`${item===1 ? 'background:#0B3591;color:white':'color: #333333;'}`" 
         style="margin-left:10px;font-size: 14px;width: 38px;height: 38px;text-align:center;line-height:38px; border: 1px solid #DCDCDC" :key="item">
           {{item++}}
          </div>
       </template>
        <div style="margin-left:10px;font-size: 14px;color: #333333;width: 38px;height: 38px;text-align:center;line-height:38px;"> ...</div>
        <div style="margin-left:10px;font-size: 14px;color: #333333;width: 38px;height: 38px;text-align:center;line-height:38px;; border: 1px solid #DCDCDC"> 8 </div>
      
        <div style="margin-left:10px;font-size: 14px;color: #333333;width: 92px;height: 38px;text-align:center;line-height:38px; border: 1px solid #DCDCDC">
          下一页》
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabContent3',
  data(){
    return {
      list: [{
        day: "22",
        month: "6月",
        image: require("../assets/tab3/1.png"),
        title: "金融科技巨头推出AI信贷评估系统，加速小微企业贷款审批",
        desc: "为了进一步推动金融数字化转型，某金融科技领军企业近日宣布上线一款基于人工智能的信贷评估系统。该系统利用机器学习算法，能够在几分钟内完成小微企业贷款申请的信用评估，极大缩短审批时间，提升金融服务效率，助力实体经济发展... "
      },
      {
        day: "23",
        month: "6月",
        image: require("../assets/tab3/2.png"),
        title: "全国首个区块链保险理赔平台上线，实现赔付透明化",
        desc: "一家保险公司与区块链技术公司合作，成功推出了全国首个区块链保险理赔服务平台。该平台通过分布式账本技术，确保保险索赔过程的透明性与不可篡改性，大大增强了客户信任度，标志着保险行业数字化转型的重要一步..."
      },{
        day: "24",
        month: "6月",
        image: require("../assets/tab3/3.png"),
        title: "数字货币试点城市扩大，推动支付方式革命",
        desc: "中央银行宣布增加数字货币试点城市范围，加速数字货币在零售、公共服务等领域的应用测试。此举旨在探索数字货币对传统支付体系的补充与革新，提升金融交易的安全性与便捷性，引领全球数字货币发展趋势... "
      },
      {
        day: "25",
        month: "6月",
        image: require("../assets/tab3/4.png"),
        title: "金融科技巨头推出AI信贷评估系统，加速小微企业贷款审批",
        desc: "为了进一步推动金融数字化转型，某金融科技领军企业近日宣布上线一款基于人工智能的信贷评估系统。该系统利用机器学习算法，能够在几分钟内完成小微企业贷款申请的信用评估，极大缩短审批时间，提升金融服务效率，助力实体经济发展... "
      },{
        day: "26",
        month: "6月",
        image: require("../assets/tab3/5.png"),
        title: "监管科技（RegTech）助力金融合规自动化，提升风控效率",
        desc: "面对日益复杂的金融监管环境，多家金融机构引入先进的监管科技解决方案，通过自动化工具监控交易、识别潜在风险，有效提升合规效率与精确度。这一转型不仅减轻了人工审核负担，也为金融机构赢得了更安全、高效的业务运作环境... "
      },
      {
        day: "27",
        month: "6月",
        image: require("../assets/tab3/6.png"),
        title: "传统银行携手科技巨头，共同打造智慧银行网点",
        desc: "国内多家大型商业银行与科技公司达成战略合作，共同研发智慧银行网点，利用大数据、云计算、生物识别等技术，实现网点智能化升级。顾客可通过自助设备完成开户、转账等操作，享受24小时不间断的个性化金融服务，展现金融与科技深度融合的新篇章... "
      }]
    }
  }
}
</script>

<style scoped>
.tab-content3{
  padding-bottom: 116px;
}
.banner{
  height: 600px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
 }

</style>

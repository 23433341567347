<template>
  <div class="tab-content4">
    <img src="../assets/banner4.png" class="banner">
    <div class="content">
      <div class="company-text">公司简介</div>
      <div style="display:flex">
        <div style="flex:1;position:relative;min-height:700px">
          <div style="width: 608px;font-family: PingFang SC;font-weight: 500;font-size: 16px;color: #999999;line-height: 30px;">
            湖南数研科技有限公司，自2022年3月注册成立以来，便以行业革新者的姿态，积极投身于数字化转型的浪潮之中。作
            为一家致力于提供全面数字化技术服务平台的高新技术企业，数研科技以其卓越的技术实力与深刻的行业洞察，为各行业客户量身打造专属的数字化解决方案，
            加速推动传统业务模式向智能化、高效化转型。
            <br/> <br/>
            在金融领域，湖南数研科技有限公司尤为凸显其专业优势，专注于为消费金融、小额贷款、保险、基金等关键行业提供银行代收付通道科技服务。
            这一核心服务不仅极大地简化了金融交易流程，增强了资金流动的安全性和时效性，还通过与多家银行系统的深度对接，实现了跨行代收付的无缝连接。
            数研科技采用先进的加密技术和多重安全防护措施，确保每一笔交易都在严格监管下安全进行，为客户构筑起坚固的信任基石。
            <br/> <br/>
            公司汇聚了一批在金融科技、大数据处理、云计算及人工智能等领域拥有丰富经验的顶尖人才，形成了强大的技术研发与创新能力。
            团队成员紧密协作，从需求分析、系统设计、开发实施到后期运维，全方位保障项目的顺利推进与落地，助力客户快速响应市场变化，提升业务竞争力。
          </div>
          <div style="margin-top:20px;display:flex;justify-content: space-around;align-items: center;width: 920px;height: 220px;border: 1px solid #DCDCDC;position:absolute;z-index:1;background:white;">
            <div style="display:flex;flex-direction: column;align-items:center">
              <div style="color: #0B3591;">
                <span style="font-size: 48px;">2022</span>
                <span style="font-size:14px">年</span>
              </div>
              <div style="font-size: 14px;color: #666666;">公司成立</div>
            </div>
            <div style="display:flex;flex-direction: column;align-items:center">
              <div style="color: #0B3591;">
                <span style="font-size: 48px;">500</span>
                <span style="font-size:14px">+</span>
              </div>
              <div style="font-size: 14px;color: #666666;">团队规模</div>
            </div>
            <div style="display:flex;flex-direction: column;align-items:center">
              <div style="color: #0B3591;">
                <span style="font-size: 48px;">30000</span>
                <span style="font-size:14px">家</span>
              </div>
              <div style="font-size: 14px;color: #666666;">服务客户</div>
            </div>
          </div>
        </div>
        <img src="../assets/tab4/company.png" class="company-image">
      </div>
    </div>
    <img src="../assets/tab4/company-intro.png" style="height:858px;margin-top:80px">
    <div class="content">
      <div class="business-text">
        <span>经营理念</span>
      </div>
      <div style="font-family: PingFang SC;font-size: 16px;color: #666666;line-height: 30px;">
        <div style="text-indent: 30px;">
          根植于“共创价值，共享成长”的核心信念，致力于构建一个以客户为中心、创新驱动、协同共赢的商业生态。
          我们坚信，卓越的客户服务是企业发展的基石，因此我们将不断倾听客户需求，以个性化、高效、贴心的服务体验超越期待，确保每位客户都能感受到量身定制的关怀与价值。
        </div>
        <div style="text-indent: 30px;">
          在创新驱动方面，我们鼓励团队拥抱变化，勇于探索未知领域，通过持续的技术革新和模式创新，为企业注入不竭的发展动力。我们相信，唯有不断前进，才能在快速迭代的市场中保持领先。</div>
        <div style="text-indent: 30px;">
          协同共赢则是我们对外合作的基本原则。我们寻求与合作伙伴建立长期、稳定、互信的关系，通过资源共享、优势互补，共同开拓市场，应对挑战，实现双方乃至多方的利益最大化。
          同时，我们重视社会责任，力求在企业发展的同时，为社会的可持续发展贡献力量，实现经济效益与社会效益的和谐统一。
        </div>
      </div>
      <div class="grid">
        <img :src="item" v-for="(item,index) in list" :key="index" style="height:300px;">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabContent4',
  data(){
    return {
      list: [
        require("../assets/tab4/1.png"),
        require("../assets/tab4/2.png"),
        require("../assets/tab4/3.png")
      ],
    }
  }
}
</script>

<style scoped>
.tab-content4{
  padding-bottom: 116px;
}
.banner{
  height: 600px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 0 auto;
 }
.company-text{
  position: relative;
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
  padding-bottom: 20px;
  margin-top: 80px;
  margin-bottom: 30px;
}
.company-text::after{
  content: '';
  position: absolute;
  width: 40px;
  height: 2px;
  background: #0B3591;
  bottom: 0;
  left: 0;
}
.company-image{
  width: 540px;
  height: 580px;
}
.business-text{
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
  margin: 80px 0 60px 0;
  text-align: center;
}
.business-text >span{
  position: relative;
  padding-bottom: 28px;
}
.business-text >span::after{
  content: '';
  position: absolute;
  width: 40px;
  height: 2px;
  background: #0B3591;
  bottom: 0;
  left: 0;
  right: 0;
  margin:auto
}


 .grid{
  margin-top:60px;
  /* 设置容器布局为grid布局 */
  display: grid;
  grid-gap:30px;
  /* 指定每一列的宽度 每个宽度中间用空格隔开 */
  grid-template-columns: 1fr 1fr 1fr;
 }
</style>

<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-03-21 11:28:18
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-09-15 08:50:44
 * @FilePath: \dalian\src\components\FooterContent.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="footer-content">
    <div>Copyright © 湖南数研科技有限公司 All Rights Reserved. </div>
    <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备2022024015号 </a>
    <div>地址：湖南省长沙市岳麓区观沙岭街道潇湘北路易宝产发大厦16F </div>
    <div>电话：400-081-9091 </div>
  </div>
</template>

<script>
export default {
  name: 'FooterContent'
}
  
</script>

<style scoped>
.footer-content{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
  color: #ffffff;
  font-size: 13px;
  background: #474747;
  gap: 10px;
}
a{
  color: #ffffff;
  font-size: 13px;
}
</style>

<template>
  <div class="tabs">
    <template v-for="(item,index) in list">
      <div class="tabs-item" :class="[showSplit && activeIndex!==index ? 'normal-item': '']" :key="index">
        <span :class="[activeIndex===index ? 'active': '']">{{item}}</span>
      </div>
      <div class="tabs-item split-line" :key="index" v-if="showSplit && index!==list.length-1"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'GridTitle',
  props: {
    list: {
      type: Array,
      default: ()=>[]
    },
    showSplit: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      activeIndex: 0,
    }
  }
}
</script>

<style scoped>
.tabs{
  display: flex;
  align-items: center;
  margin: 0 -15px;
  font-size: 16px;
}
.tabs > .tabs-item{
  position: relative;
  line-height: 36px;
  margin: 0 15px;
  color: #0B3591;
}
.tabs > .normal-item{
  color: #333333 !important;
}
.tabs > .tabs-item > .active::after{
  content: '';
  position: absolute;
  width: auto;
  height: 2px;
  background: #0B3591;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.tabs > .split-line{
  height: 16px;
  background: #C6C6C6;
  width: 2px;
}
</style>

<template>
  <div class="tab-content1">
    <img src="../assets/banner1.png" class="banner">
    <div class="content-1">
      <div class="left">
        <img src="../assets/tab1/about.png" class="about-image">
        <div style="margin-top:40px">
          <Tabs :list="tabs1" />
          <div class="company-name">湖南数研科技有限公司</div>
          <div class="company-intro">
            湖南数研科技有限公司，自2022年3月注册成立以来，便以行业革新者的姿态，积极投身于数字化转型的浪潮之中。作为一家致力于提供全面数字化技术服务平台的高新技术企业，数研科技以其卓越的技术实力与深刻的行业洞察，为各行业客户量身打造专属的数字化解决方案，加速推动传统业务模式向智能化、高效化转型...
          </div>
        </div>
      </div>
      <img src="../assets/tab1/company-image.png" class="company-image">
    </div>

    <div class="card-box">
      <div class="card-box-item" v-for="(item,index) in card" :key="index">
        <img :src="item.icon" class="card-box-item-icon">
        <div class="card-box-item-right">
          <div class="card-box-item-right_title">
            <div class="card-box-item-right_name">{{item.name}}</div>
            <div>{{item.english}}</div>
          </div>
          <div class="card-box-item-right_desc">
            {{item.desc}}
          </div>
        </div>
      </div>
    </div>

    <div class="content-2" style="margin-top:100px">
      <div class="product-display">
        <img src="../assets/tab1/product.png" class="product-image">
        <Tabs :list="productTabs" showSplit/>
      </div>
      <div class="product-box">
        <img :src="item" style="width:100%;height:100%" v-for="(item,index) in product" :key="index">
      </div>
    </div>
    <div style="margin-top:60px;text-align:center;font-weight: bold;font-size: 18px;color: #0B3591;line-height:20px">
      READ MORE <span style="width: 32px;height: 4px;">→</span>
    </div>
    <div class="content-2" style="margin-top:115px">
      <div style="text-align:center;margin-bottom:60px">
        <img src="../assets/tab1/news.png" class="product-image">
      </div>
      <div class="news-box">
        <div class="news-left">
          <img src="../assets/tab1/news-left.png" style="width:100%;height:353px">
          <div style="padding:18px 20px;display:flex;">
            <div style="flex-shrink: 0;width: 71px;height: 74px;background: #0B3591;display:flex;flex-direction: column;justify-content:center;align-items:center;">
              <div style="font-family: Microsoft YaHei;font-weight: bold;font-size: 36px;color: #FFFFFF;">26</div>
              <di style="font-size: 14px;color: #FFFFFF;">2024/06</di>
            </div>
            <div style="margin-left:15px">
              <div style="font-size: 16px;color: #0B3591;">监管科技（RegTech）助力金融合规自动化，提升...</div>
              <div style="font-size: 14px;color: #808080;line-height:24px">面对日益复杂的金融监管环境，多家金融机构引入先进的监管科技解决方案，通过自动化工具监控交易、识别潜在风...</div>
            </div>
          </div>
        </div>
        <div class="news-right">
          <div class="news-right-item" v-for="(item,index) in news" :key="index">
            <img :src="item.icon" style="flex-shrink: 0;width:128px;height:90px">
            <div style="margin-left:16px">
              <div style="font-size: 16px;color: #0B3591;">{{item.title}}</div>
              <div style="font-size: 12px;color: #CCCCCC;">{{item.date}}</div>
              <div style="font-size: 14px;color: #808080;line-height: 24px;">{{item.desc}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from './Tabs.vue'
export default {
  name: 'TabContent1',
  components:{
    Tabs
  },
  data(){
    return {
      tabs1:["公司简介","企业文化","经营理念"],
      card:[{
        icon: require("../assets/tab1/card-1.png"),
        name: '品牌',
        english: "BRAND",
        desc: "在金融领域，湖南数研科技有限公司尤为凸显其专业优势，专注于为消费金融、小额贷款、保险、基金等关键行业提供银行代收付通道科技服务。"
      },{
        icon: require("../assets/tab1/card-2.png"),
        name: '技术',
        english: "TECHNOLOGY",
        desc: "公司汇聚了一批在金融科技、大数据处理、云计算及人工智能等领域拥有丰富经验的顶尖人才，形成了强大的技术研发与创新能力。团队成员紧密协作，从需求分析、系统设计、开发实施到后期运维，全方位保障项目的顺利推进与落地，助力客户快速响应市场变化，提升业务竞争力。"
      },{
        icon: require("../assets/tab1/card-3.png"),
        name: '素质',
        english: "QUALITY",
        desc: "我们拥有一支500余人的高素质员工队伍，其中80%拥有本科学历及多年的行业经验，20%有着硕士以上学历。他们年轻而富有创意，能力突出、专业素质过硬，凭借着对互联网事业的狂热追求，不断开拓与进步，实现了我们在持术领域上的飞跃。"
      },{
        icon: require("../assets/tab1/card-4.png"),
        name: '服务',
        english: "SERVICE",
        desc: "尤为注重用户体验与服务品质，通过不断优化升级其技术平台，确保服务的稳定性与灵活性。针对不同行业特性，提供高度定制化的服务策略，以满足客户多样化、个性化的需求，真正实现技术与业务的深度融合。"
      }],
      product: [
        require("../assets/tab1/product-1.png"),
        require("../assets/tab1/product-2.png"),
        require("../assets/tab1/product-3.png")
      ],
      productTabs:["银行代收付通道","软件技术开发","数字化服务"],
      news: [{
        icon: require("../assets/tab1/news-1.png"),
        title: '金融科技巨头推出AI信贷评估系统，加速小微企业贷款审批',
        date: "2024/06/22",
        desc: "为了进一步推动金融数字化转型，某金融科技领军企业近日宣布上线一款基于人工智能的信贷评估系统。该系统利用机器学习算法，能够在几分钟内..."
      },{
        icon: require("../assets/tab1/news-2.png"),
        title: '中小企业网络营销6大问题，您占了几个？',
        date: "2024/06/23",
        desc: "近年来，我国企业纷纷触网，上网中小企业数量也大幅度增加，网络营销已获得初步应用。同时，在中小企业网络营销的进一步发展过程中既存在着......"
      },{
        icon: require("../assets/tab1/news-3.png"),
        title: '全国首个区块链保险理赔平台上线，实现赔付透明化',
        date: "2024/06/24",
        desc: "一家保险公司与区块链技术公司合作，成功推出了全国首个区块链保险理赔服务平台。该平台通过分布式账本技术，确保保险索赔过程的透明性与不......"
      },{
        icon: require("../assets/tab1/news-4.png"),
        title: '数字货币试点城市扩大，推动支付方式革命',
        date: "2024/06/25",
        desc: "中央银行宣布增加数字货币试点城市范围，加速数字货币在零售、公共服务等领域的应用测试。此举旨在探索数字货币对传统支付体系的补充......"
      }]
    }
  }

}
</script>

<style scoped>
.tab-content1{
  padding-bottom: 100px;
}
.banner{
  height: 800px;
  width: 100%;
 }
.content-1 {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 100px auto;
}
.left{
  padding-right: 130px;
}
.about-image{
  width: 380px;
  height: 118px;
}
.company-image{
  width: 540px;
  height: 580px;
}

.company-name {
  margin-top: 40px;
  color: #333333;
  font-size: 24px;
}
.company-intro {
  margin-top: 20px;
  color: #333333;
  font-size: 16px;
  line-height: 24px;
}

.card-box{
  min-height: 320px;
  background: #0B3591;
  box-sizing: border-box;
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.card-box-item {
  display: flex;
  border-right: 1px solid #3E99DF;
  padding: 68px 60px 65px;
  box-sizing: border-box;
}
.card-box > :last-child{
  border-right: none;
}
.card-box-item-right{
  padding-left: 20px;
}
.card-box-item-right_title{
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
.card-box-item-icon {
  width: 42px;
  height: 36px;
}
.card-box-item-right_name{
  position: relative;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 10px;
  margin-right: 10px;
}
.card-box-item-right_name::after{
  content: '';
  position: absolute;
  width: auto;
  height: 2px;
  background: white;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.card-box-item-right_desc{
  margin-top: 16px;
  font-family: PingFang SC;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 24px;
  text-align: justify;
}
.content-2{
  width: 1200px;
  margin: auto;
}
.product-display{
  display: flex;
  justify-content: space-between;
}
.product-image{
   width: 377px;
   height: 118px;
}
.product-box{
  margin-top: 60px;
  display: grid;
  grid-gap:30px;
  grid-template-columns: 1fr 1fr 1fr;
}

.news-box{
  display: flex;
  color: #808080;
  font-size: 14px;
}

.news-left{
  background: #F5F5F5;
  width: 520px;
  flex-shrink: 0;
}
.news-right{
  margin-left: 30px;
}
.news-right-item{
  display: flex;
  border-bottom: 1px dashed #D9D9D9;
  padding-bottom: 16px;
  margin-bottom: 18px;
}
.news-right > .news-right-item:last-child{
  margin-bottom: 0;
  border-bottom: unset;
  padding-bottom: 0;
}
 .grid-1{
    /* 设置容器布局为grid布局 */
    display: grid;
    grid-gap:25px;
    /* 指定每一列的宽度 每个宽度中间用空格隔开 */
    grid-template-columns: 370px 1fr 1fr;
      /* 指定每一行的宽度 每个宽度中间用空格隔开 */
    /* grid-template-rows:285px; */
    /* 单元格顺序,可以实现避免空格 */
    grid-auto-flow: row dense;
    padding: 67px 0 57px 0;
 }
 .grid-1-item-1{
  /* 实现纵向占两行 */
    grid-row: span 2;
 }
  .grid-1-item-1>img{
    width: 370px;
    height: 600px;
  }
  .grid-1-item-2>img{
    width: 387px;
    height: 285px;
  }

  .grid-2{
    /* 设置容器布局为grid布局 */
    display: grid;
    grid-gap:21px;
    /* 指定每一列的宽度 每个宽度中间用空格隔开 */
    grid-template-columns: 1fr 1fr 1fr;
    padding: 65px 0 53px 0;
  }
  .grid-2-item{
    height: 458px;
    border: 1px solid #CECECE;
    padding: 3px;
    box-sizing: border-box;
  }
  .grid-2-item>img{
    height: 249px;
  }

  .title{
    color: #666666;
    font-size: 30px;
    font-family: Alibaba PuHuiTi;
  }
  .desc{
    color: #666666;
    font-size: 15px;
    font-family: Alibaba PuHuiTi;
    margin: 5px 0 23px 0;
    text-align: justify;
  }
  .btn-box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 36px;
    font-size: 18px;
    color: #4068C7;
    border: 1px solid #4068C7;
    border-radius: 18px;
    box-sizing: border-box;
  }
  .btn-box>img{
    height: 14px;
  }
</style>
